import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import { CardActionArea } from '@mui/material';
import ReactCardFlip from 'react-card-flip';
// import { makeStyles } from '@mui/styles';
// import { useTheme } from '@mui/material/styles';
// import CardContent from '@mui/material/CardContent';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import Collapse from '@mui/material/Collapse';
// import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
// import { faAirbnb } from '@fortawesome/free-brands-svg-icons';
// import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

function Stone(props) {
  const [flip, setFlip] = React.useState(false);
  const services = <span className='service'>{props.service1}<br/>{props.service2}</span>
  return (
    <Card sx={{maxWidth: 400, height:450, width: 240, backgroundColor: 'transparent', fontFamily: 'Open Sans, sans-serif',
                "&:hover": { transform: "translateY(-10px)"}}} >
        <ReactCardFlip isFlipped={flip} flipDirection="horizontal">
            <CardActionArea className="front" onClick={() => setFlip(!flip)}>
                <CardMedia
                  sx={{ margin:'0 auto', background:props.bgc, width:props.imgwidth, objectFit:props.bgsize}}
                  image={props.imgfile}
                  title={props.imgtitle}
                  height='300px'
                  component='img'
                />
                <CardHeader sx={{ color: '#000000', fontFamily: 'Open Sans, sans-serif' }}
                    title={props.title}
                    subheader={services}
                />
            </CardActionArea>
            <CardActionArea className="back" onClick={() => setFlip(!flip)}>
                <Box sx={{ display: 'flex', justifyContent:'center',  flexDirection:'column', width: '100%', height:450,
                           backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition: 'center',
                           backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${props.imgfileBack})`,
                }}>
                    <CardHeader title={props.title} sx={{ color: '#ffffff', fontFamily: 'Open Sans, sans-serif' }} />
                    <CardActions className="back" onClick={() => setFlip(!flip)}
                                sx={{display:'flex', justifyContent:'center', backgroundColor: 'transparent'}}>
                          <a href={props.link} target="_blank" rel="noopener noreferrer" sx={{  }} >
                            <FontAwesomeIcon icon={faLink} color="#ffffff" size="2x"  />
                          </a>
                   </CardActions>
               </Box>
               {/*<IconButton
                   className={!expanded ? 'expanded' : 'expand' }
                   onClick={handleExpandClick}
                   aria-expanded={expanded}
                   aria-label="show more"
                 >
                   <FontAwesomeIcon icon={faAngleDown} color="#000000" size="1x" />
                </IconButton>*/}
               {/*<Collapse in={expanded} timeout="auto" unmountOnExit>
                 <CardContent>
                   <Typography component={'p'} sx={{ color: "#000000" }}>
                     {props.ptext1}
                   </Typography>
                   <Typography component={'p'} sx={{ color: "#000000" }}>
                     {props.ptext2}
                   </Typography>
                 </CardContent>
               </Collapse>*/}
            </CardActionArea>
      </ReactCardFlip>
    </Card>
  );
}

class Project extends React.Component {
	render() {
    const { imgfile, imgfileBack, imgtitle, imgwidth, title, descMain, link, service1, service2, bgc, bgsize, ptext1, ptext2 } = this.props;
		return (
			<div>
				<Stone
                  imgfile = { imgfile }
                  imgfileBack = { imgfileBack }
                  imgtitle = { imgtitle }
                  imgwidth = { imgwidth }
                  title = { title }
                  service1 = { service1 }
                  service2 = { service2 }
                  descMain = { descMain }
                  link = { link }
                  bgc = { bgc }
                  bgsize = { bgsize }
                  ptext1 = { ptext1 }
                  ptext2 = { ptext2 }
                 />
			</div>
		);
	}
}

export {Project};
