import React from 'react';
import PtsIntro from './sectionAf';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MenuAppBar from './menuBar';
import Projects from './projects';
import {ScrollTop} from './scrollTop';
import { useInView } from 'react-intersection-observer';
// import useOnScreen from './showOnScroll';
// import ProjectsTabs from './sectionA.js';


function Main(props) {
    const [ref1, inView1] = useInView({ threshold: 0.6 });
    const [ref2, inView2] = useInView({ threshold: 0.6 });
    // const ref1 = React.useRef();
    // const ref3 = React.useRef();
    // const isVisible1 = useOnScreen(ref1,"-100px");
    // const isVisible3 = useOnScreen(ref3,"-100px");
    return (
        <div className="App-main">
           <MenuAppBar />
           <div className="section fs w-100" id="PtS">
            <PtsIntro />
           </div>
           {/*<div className="section fs w-100" id="PtS">
                <PtsIntro />
            </div>
            <div className="section fs w-100">
                <ProjectsTabs />
            </div>
            <div className="section fs w-100">
                <CarouselPtS />
            </div>*/}
           <div className="section fs w-100 bg bg1" id="team">
               <Slide in={inView1} timeout={4000} direction={'up'}>
                   <p className="text-a">
                       Team | {"\n"}{"\n"}
                       a network of professionals{"\n"}
                       available for challenging{"\n"}
                       co-operations
                   </p>
               </Slide>
               <div className={!inView1 ? "bg-cover" : "bg-cover animate"} ref={ref1}></div>
           </div>
           <Projects />
           <div className="section fs w-100 bg bg3" id="contact">
               <Grow in={inView2} timeout={4000} direction={'up'}>
                   <p className="text-a">
                       Contact | {"\n"}{"\n"}
                       Konstantinos Giampouras | Chemical Engineer {"\n"}
                       Stelida, Naxos, 84300, Greece {"\n"}
                       <a href="mailto:projectsthestones@gmail.com" target="_blank" rel="noopener noreferrer" className="link" >
                        projectsthestones@gmail.com
                       </a>
                       {"\n"}
                       <a href="tel:00306944720770" target="_blank" rel="noopener noreferrer" className="link" >+30 6944 720 770</a>
                       <br/><br/>
                       <a href="https://www.instagram.com/projectsthestones/" target="_blank" rel="noopener noreferrer" className="link" >
                         <Tooltip title='instagram' placement="bottom">
                           <i className='fa fa-instagram faicon'  />
                         </Tooltip>
                       </a>
                   </p>
               </Grow>
               <div className={!inView2 ? "bg-cover" : "bg-cover animate"} ref={ref2}></div>
           </div>
           <ScrollTop {...props}>
                <Fab size="small" aria-label="scroll back to top" className="scroll-top">
                    <KeyboardArrowUpIcon />
                </Fab>
           </ScrollTop>
        </div>
    )
}

export {Main};
