import {Project} from './stone.js';
import stone2 from '../images/p2.png';
import stone3 from '../images/p3.jpg';
import stone4 from '../images/p4.png';
import ats from '../images/ats-sketch.png';
import atsB from '../images/ats-sketch-plain.png';

export default function Projects() {
    return (
        <div className="projects-container" id="projects">
            <div className="proj-item">
                <Project title={<span>certification</span>} 
                         service1='HAACP' 
                         service2='ISO22000'
                         imgfile={stone4}
                         imgfileBack={stone4}
                         imgtitle='certification'
                         imgwidth='100%'
                />
            </div>
            <div className="proj-item">
                <Project title={<span>project<br/>consulting</span>} 
                         service1='touristic business' 
                         service2='construction'
                         imgfile={stone2}
                         imgfileBack={stone2}
                         imgtitle='project consulting'
                         imgwidth='100%'
                />
            </div>
            <div className="proj-item">
                <Project title={<span>design &<br/>architecture</span>}
                         service1='3d sketch' 
                         service2='render' 
                         imgfile={stone3}
                         imgfileBack={stone3}
                         imgtitle='design & architecture'
                         imgwidth='100%'
                />
            </div>           
            <div className="proj-item">
                <Project title={<span>Apartments:<br/>The Stones</span>} 
                         service1='accomodation'
                         bgc = 'linear-gradient(270deg, #666, #fff)'
                         bgsize ='contain'
                         imgfile={ats}
                         imgfileBack={atsB} 
                         imgtitle='Apartments: The Stones'
                         imgwidth='100%'
                         link='https://apartmentsthestones.gr'
                         ptext1='The first implementations were Emerystone and Limestone two apartment reconstruction projects with respect to the architecture of Cyclades, minimal and operationally pleasurable'
                         ptext2=''
                 />
            </div>
        </div>
    );
}
