import * as React from 'react';
// import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import Link from '@mui/material/Link';
import { HashLink } from 'react-router-hash-link';

function LinkTab(props) {
  return (
      <Tab component={HashLink} to={props.path} label={props.label}
           sx={{ color: '#ffffff', padding: {xs:'5px 7px', md:'12px 16px'}, fontSize:{xs:'0.8em',md:'0.8em'}, minWidth: {xs:'50px',md:'90px'} }}
           {...props}
      />
  );
}

export default function NavTabs() {
  const [value, setValue] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
      <Tabs value={value} onChange={handleChange} variant="scrollable" aria-label="nav tabs"
            sx={{ padding:'0px 20px'}}
            TabIndicatorProps={{  }} >
        <LinkTab label="PtS" path="/#PtS" />
        <LinkTab label="Team" path="/#team" />
        <LinkTab label="Projects" path="/#projects" />
        <LinkTab label="Contact" path="/#contact" />
      </Tabs>
  );
}
