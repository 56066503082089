import React from 'react';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Main} from './components/Main';
// import {Header} from './components/Header';
// import {Footer} from './components/Footer';

const theme = createTheme({
  palette: {
    primary: {
      main: '#21574A',
    },
    secondary: {
      main: '#7ab340',
    },
  },
  components: {
      MuiTabs: {
          styleOverrides: {
            indicator: {
              backgroundColor: '#21574A',
            },
          },
      },
      MuiTab:{
          styleOverrides: {
              root: {
                '&.Mui-selected': { textShadow: '1px 1px 1px #7ab340', fontWeight:'700' },
            },
          }
      },
      MuiCardHeader: {
          styleOverrides: {
            subheader: {
              color: '#21574A',
            },
          },
      }
  },
  typography: {
      fontFamily: 'Open Sans, sans-serif',
  }
});


function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Main />
      </ThemeProvider>
    </div>
  );
}

export default App;
