import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Fade from '@mui/material/Fade';
import NavTabs from './navBar';
import sketch from '../images/pts-logo-main.png';

export default function MenuAppBar() {
  const [isVisible, setIsVisible] = React.useState(false);
  // const [height, setHeight] = React.useState(0)
  React.useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () =>  //performs our clean-up during un-mounting
           window.removeEventListener("scroll", listenToScroll);
  }, [])
  const listenToScroll = () => {
        let heightToShow = 500;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        // setHeight(winScroll);
        if (winScroll > heightToShow) {
             setIsVisible(true);
        } else {
             setIsVisible(false);
        }
  };
  return (
      <AppBar position="fixed" sx={{ backgroundColor: 'transparent', boxShadow: '0 0 0 0 transparent', left:0, }}>
        { isVisible &&
            <Fade in={isVisible} timeout={1500}>
                <Toolbar sx={{ justifyContent:{xs:'center', md:'space-between', lg:'space-between'}, backgroundColor: 'transparent',
                               width:'100%', paddingLeft: {xs:0,md:'20px'}, paddingRight:{xs:0,md:'20px'} }}>
                    <Box component="img" src={sketch} alt={"PtS"} sx={{ width: {xs:'2em',md:'3em'}, padding: '10px', display:{xs:'none',md:'block'} }} />
                    {/*<span style={{color:'#000000'}}>height: {height} - {isVisible?"show":"hide"}</span>*/}
                    <NavTabs />
                </Toolbar>
            </Fade>
         }
      </AppBar>
  );
}
