import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Arrow from './arrow';
import logo from '../images/pts-logo.png';
import logoL from '../images/pts-logo-w1.png';
import logoR from '../images/pts-logo-w2.png';

// import { useTheme } from '@mui/material/styles';

export default function PtsIntro() {
  // const theme = useTheme();
  const [showLogo, setShowLogo] = React.useState(true);
  const [showText1, setShowText1] = React.useState(false);
  const [showText2, setShowText2] = React.useState(false);
  const toggleT1 = () => { setShowText1(!showText1); setShowText2(false); setShowLogo(false); }
  const toggleT2 = () => { setShowText2(!showText2); setShowText1(false); setShowLogo(false); }
  const toggleL0 = () => { setShowText1(false); setShowText2(false); setShowLogo(true); }

  const textL = (
      <span>Idea  Implementation{"\n"}{"\n"}with partners that inspire{"\n"}for people that desire</span>
  );
  const textR = (
      <span>We are centered on ideas of endurance, stability and permanence representing the ability to be grounded and connected
       with the earth strong, versatile and easily accessible,{"\n"}{"\n"} like stones</span>
  );

  return (
    <Box sx={{ position: 'relative', width: '100%', height:{xs:'85%',md:'95vh'}, display:'flex',
               flexDirection:'column', justifyContent:'space-between', alignItems:'center' }}>
      {showLogo &&
           <Box sx={{ display:'flex', justifyContent:'center', height:'100%', width:'100%', position:'relative' }}>
               <Button onClick={toggleT1} variant={'text'} disableRipple sx={{ flexBasis:'45%', padding:0, '&:hover':{backgroundColor:'transparent' }}}></Button>
               <Button onClick={toggleT2} variant={'text'} disableRipple sx={{ flexBasis:'55%', padding:0, '&:hover':{backgroundColor:'transparent' }}}></Button>
               <img src={logo} className="pts-logo-center" alt="PtS" />
           </Box>}
      {showText1 &&
          <Box sx={{ display:'flex', justifyContent:'center', height:'100%', width:'100%', position:'relative' }}>
              <Button onClick={toggleL0} variant={'text'} disableRipple sx={{ flexBasis:'45%', padding:0, '&:hover':{backgroundColor:'transparent'}}}></Button>
              <Button onClick={toggleT2} variant={'text'} disableRipple sx={{ flexBasis:'55%', padding:0, '&:hover':{backgroundColor:'transparent'}}}></Button>
              <img src={logoL} className="pts-logo-center" alt="PtS" />
              <div className="text-tab-1">{textL}<div className="lineBottom"></div></div>
          </Box>}
      {showText2 &&
          <Box sx={{ display:'flex', justifyContent:'center', height:'100%', width:'100%', position:'relative' }}>
              <Button onClick={toggleT1} variant={'text'} disableRipple sx={{ flexBasis:'45%', padding:0, '&:hover':{backgroundColor:'transparent'}}}></Button>
              <Button onClick={toggleL0} variant={'text'} disableRipple sx={{ flexBasis:'55%', padding:0, '&:hover':{backgroundColor:'transparent'}}}></Button>
              <img src={logoR} className="pts-logo-center" alt="PtS" />
              <div className="text-tab-2">{textR}<div className="lineBottom"></div></div>
          </Box>}
        <Arrow />
    </Box>
  );
}
